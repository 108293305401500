import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'default-Québec',
    predictionPlace: {
      address: 'Sherbrooke, Canada',
      bounds: new LatLngBounds(new LatLng(44.880926, -76.364666), new LatLng(59.92248, 24.78287)),
    }
  }
];
export default defaultLocations;
